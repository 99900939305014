<template>
    <FormKit
        id="signup-form"
        type="form"
        v-model="formData"
        :actions="false"
        :config="config"
        #default="context"
    >
        <!-- Subscription Information section -->
        <FormSectionHeader
            class="pb-4"
            title="Subscription Information"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Organization Name"
                    name="institution"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'accountLevel')
                    "
                />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                <div
                    class="w-full"
                    :class="{ 'col-span-2': isNoPlanSelected }"
                >
                    <FormKit
                        type="select"
                        label="Subscription Level"
                        name="planId"
                        @input="selectPlanOption"
                        @node="setPlanNode"
                        :value="`${formData.planId}`"
                        :options="planOptions"
                        :ignore="true"
                        :preserve="true"
                        :validation="[
                            ['required'],
                            ['*+not', enterpriseOption?.value ?? 'NaN'],
                        ]"
                        :validation-messages="{
                            required: 'This field is required.',
                            not: 'Please contact IPI in order to create an Enterprise plan organization.',
                        }"
                        @keydown.enter.prevent.stop="
                            focusFormInput(
                                $event,
                                isNoPlanSelected
                                    ? 'country'
                                    : 'subscriptionLength'
                            )
                        "
                    />
                </div>
                <div
                    class="w-full"
                    v-if="isAnyPlanSelected"
                >
                    <FormKit
                        type="select"
                        label="Subscription Length"
                        name="subscriptionLength"
                        :options="durationOptions"
                        :preserve="true"
                        validation="required:trim"
                        :validation-messages="{
                            required: 'This field is required.',
                        }"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'country')
                        "
                    />
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <FormKit
                type="group"
                name="address"
            >
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3">
                    <div
                        class="w-full"
                        :class="{ 'col-span-2': !isRegionInputVisible }"
                    >
                        <FormKit
                            type="select"
                            label="Country"
                            name="country"
                            placeholder="Select Country"
                            :options="countryOptions"
                            :preserve="true"
                            :validation="[
                                ['required', 'trim'],
                                ['*+not', 'placeholder'],
                            ]"
                            :validation-messages="{
                                not: 'Please select a valid country.',
                            }"
                            @keydown.enter.prevent.stop="
                                focusFormInput(
                                    $event,
                                    isRegionInputVisible ? 'state' : 'city'
                                )
                            "
                        />
                    </div>
                    <div
                        class="w-full"
                        v-if="isRegionInputVisible && isStateInputVisible"
                    >
                        <FormKit
                            v-if="isStateInputVisible"
                            type="select"
                            label="State"
                            name="state"
                            placeholder="Select State"
                            :options="stateOptions"
                            :preserve="true"
                            :validation="[
                                ['required', 'trim'],
                                ['*+not', 'placeholder'],
                            ]"
                            :validation-messages="{
                                not: 'Please select a valid state.',
                            }"
                            @keydown.enter.prevent.stop="
                                focusFormInput($event, 'city')
                            "
                        />
                    </div>
                    <div
                        class="w-full"
                        v-if="isRegionInputVisible && isProvinceInputVisible"
                    >
                        <FormKit
                            v-if="isProvinceInputVisible"
                            type="select"
                            label="Province"
                            name="state"
                            placeholder="Select Province"
                            :options="provinceOptions"
                            :preserve="true"
                            :validation="[
                                ['required', 'trim'],
                                ['*+not', 'placeholder'],
                            ]"
                            :validation-messages="{
                                not: 'Please select a valid province.',
                            }"
                            @keydown.enter.prevent.stop="
                                focusFormInput($event, 'city')
                            "
                        />
                    </div>
                </div>
                <div class="w-full">
                    <FormKit
                        type="text"
                        label="City"
                        name="city"
                        validation="required:trim"
                        :disabled="isCityInputDisabled"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'firstName')
                        "
                    />
                </div>
            </FormKit>
        </div>
        <!-- Subsciption Contact section -->
        <FormSectionHeader
            class="pb-4 pt-4"
            title="Subsciption Contact"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="text"
                    label="First Name"
                    name="firstname"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'lastName')
                    "
                />
            </div>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Last Name"
                    name="lastname"
                    validation="required:trim"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'emailAddress')
                    "
                />
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <FormKit
                type="group"
                name="email"
            >
                <div class="w-full">
                    <FormKit
                        type="text"
                        label="Primary Contact Email"
                        name="contact"
                        validation="required:trim|email|(200)isPrimaryEmailValid"
                        :validation-rules="{ isPrimaryEmailValid }"
                        :validation-messages="{
                            isPrimaryEmailValid: 'This email address is taken.',
                        }"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'username')
                        "
                    />
                    <div
                        class="text-red-500 mb-1 text-xs"
                        v-if="hasEmailError"
                    >
                        The email entered must be unique.
                    </div>
                </div>
                <div class="w-full">
                    <FormKit
                        type="text"
                        label="Billing Contact Email"
                        name="billing"
                        validation="required:trim|email"
                        @keydown.enter.prevent.stop="
                            focusFormInput($event, 'username')
                        "
                    />
                    <div
                        class="text-red-500 mb-1 text-xs"
                        v-if="hasEmailError"
                    >
                        The email entered must be unique.
                    </div>
                </div>
            </FormKit>
            <div class="w-full">
                <FormKit
                    type="text"
                    label="Username"
                    name="username"
                    validation="required:trim|(200)isUsernameValid"
                    :validation-rules="{ isUsernameValid }"
                    :validation-messages="{
                        isUsernameValid: 'This username is taken.',
                    }"
                    autocomplete="new-password"
                    aria-autocomplete="new-password"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'password')
                    "
                />
                <div
                    class="text-red-500 mb-1 text-xs"
                    v-if="hasUsernameError"
                >
                    The username entered must be unique.
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3 max-w-2xl">
            <div class="w-full">
                <FormKit
                    type="password"
                    label="Password"
                    name="password"
                    autocomplete="new-password"
                    aria-autocomplete="new-password"
                    validation="required:trim|length:8"
                    @keydown.enter.prevent.stop="
                        focusFormInput($event, 'password_confirmation')
                    "
                />
            </div>
            <div class="w-full">
                <FormKit
                    type="password"
                    label="Re-enter Password"
                    name="password_confirmation"
                    validation="required:trim|confirm:password"
                    validation-label="Password"
                    @keydown.enter.prevent.stop="
                        clickSubmitButton($event, context)
                    "
                />
            </div>
        </div>
        <div class="mb-5">
            <div class="flex items-start">
                <FormKit
                    type="checkbox"
                    name="terms"
                    outer-class="$reset outer p-0"
                    wrapper-class="$reset wrapper flex flex-row items-center h-5 space-x-1"
                    inner-class="$reset inner block h-5 my-1"
                    input-class="$reset checkbox block h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                    label-class="$reset label block h-5 my-1 text-xs text-gray-900"
                    validation="accepted"
                    validation-visibility="dirty"
                    :validation-messages="{
                        accepted:
                            'Accepting Terms and Conditions of Use is required to create an account.',
                    }"
                    @keyup.enter.stop="
                        /** @type {HTMLElement} */ ($event?.target)?.click()
                    "
                >
                    <template #label>
                        <span class="label block ml-1 text-xs text-gray-900">
                            I agree to the
                            <a
                                :href="termsLink"
                                class="underline hover:text-gray-400"
                                target="_blank"
                            >
                                Terms and Conditions of Use
                            </a>
                        </span>
                    </template>
                </FormKit>
            </div>
        </div>
        <div>
            <VariantButton
                name="signup"
                variant="login"
                label="Subscribe"
                @click="clickSubmitButton($event, context)"
            />
        </div>
        <div
            v-if="debug"
            class="mt-4 border-gray-50 bg-gray-50 p-4"
        >
            <code>Form Data:</code>
            <pre>{{ formData }}</pre>
        </div>
    </FormKit>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onBeforeMount } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import {
        createEventHook,
        watchTriggerable,
        computedEager,
        promiseTimeout,
    } from '@vueuse/core';
    import users from '@/api/v2/users';

    // <!-- ENUMS -->
    import { CountryName, StateName } from '@/enums';

    // <!-- COMPONENTS -->
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import FormSectionHeader from '@/components/forms/partials/FormSectionHeader.vue';

    // <!-- COMPOSABLES -->
    import { useAxios } from '@/plugins/axios';
    import { useMarketingDomain } from '@/hooks/env/useMarketingDomain';
    import { useRegionSelectors } from '@/hooks/options/useRegionSelectors';
    import { usePlanSelector } from '@/hooks/options/usePlanSelector';
    import { useDurationSelector } from '@/hooks/options/useDurationSelector';
    import { useSubscriptionLength } from '@/hooks/plans';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { DurationISO } from '@/utils/datetime';
    import { add, formatISODuration } from 'date-fns';
    import { createOrganization } from '@/api/v2/organizations';
    import { submitRegistration } from '@/api/v2/cart';

    // <!-- CONSTANTS -->

    /** Configured Axios instance. */
    const axios = () => useAxios();

    // <!-- HELPERS -->

    /**
     * Define the component state.
     *
     * @param {Object} [props]
     */
    const defineState = (props = {}) => {
        /** Marketing link reference. */
        const termsLink = ref('');

        /** @type {Vue.Ref<Router.LocationQuery>} */
        const prefillQuery = ref(useRoute().query);

        /** @type {Vue.Ref<Partial<globalThis.Organization.SignUpFormData>>} */
        const initialData = ref({
            institution: '', // 'New Organization',
            planId: 5,
            planName: 'Free',
            subscriptionLength: DurationISO.format({ months: 3 }),
            address: {
                /** @type {Country.Name | ''} */
                country: CountryName.ByISO3['USA'],
                /** @type {State.Name | ''} */
                state: '', // StateName.ByISO2['US-NY'],
                city: '', // 'Rochester',
            },
            firstname: '',
            lastname: '',
            email: {
                contact: '',
                billing: '',
            },
            username: '',
            password: '',
            password_confirmation: '',
            terms: false,
        });

        /** @type {Vue.Ref<Partial<globalThis.Organization.SignUpFormData>>} */
        const formData = ref(clone({ ...initialData.value }));

        /** @type {Vue.Ref<import('@formkit/core').FormKitNode>} */
        const planNode = ref(null);

        /** Errors object. */
        const errors = {
            /** General form errors. */
            general: ref([]),
            /** Email errors. */
            email: ref([]),
            /** Username errors. */
            username: ref([]),
            /** Plan selection errors. */
            plan: ref([]),
        };

        /** @type {Vue.Ref<Partial<import('@formkit/core').FormKitProps>>} */
        const config = ref({
            delay: 250,
            validationVisibility: 'blur',
        });

        return {
            termsLink,
            prefillQuery,
            initialData,
            formData,
            planNode,
            errors,
            config,
        };
    };

    /**
     * Define the computed state.
     *
     * @param {Partial<ReturnType<defineState>> & { planSelector: ReturnType<usePlanSelector>, planDurations: ReturnType<useSubscriptionLength>, durationSelector: ReturnType<useDurationSelector> }} state
     */
    const defineComputed = (state) => {
        const {
            initialData,
            formData,
            errors,
            planSelector,
            planDurations,
            durationSelector,
        } = state;

        const enterpriseOption = computed(() => {
            const availableOptions = planSelector.state.options.value;
            return availableOptions.find((option) => {
                const { label, value } = option;
                const planId = Number.parseInt(value);
                const hasEnterpriseLabel = label === 'Enterprise';
                return !Number.isNaN(planId) && hasEnterpriseLabel;
            });
        });

        const planOptions = computed(() => {
            const currentOption = initialData.value.planId;
            const availableOptions = planSelector.state.options.value;
            return availableOptions.map((option) => {
                const { value } = option;
                const planId = Number.parseInt(value);
                const disabled = planId < currentOption;
                return {
                    ...option,
                    attrs: { disabled },
                };
            });
        });

        const durationOptions = computed(() => {
            const availableOptions = durationSelector.state.options.value;
            const currentPlanName = formData.value?.planName ?? '';
            const minimum = DurationISO.magnitude(
                planDurations.getMinimumSubscriptionLengthByPlanName(
                    currentPlanName
                )
            );
            const maximum = DurationISO.magnitude(
                planDurations.getMaximumSubscriptionLengthByPlanName(
                    currentPlanName
                )
            );
            return availableOptions.filter((option) => {
                const { value } = option;
                const magnitude =
                    value === '' ? 0 : DurationISO.magnitude(value);
                return (
                    currentPlanName === '' ||
                    (magnitude >= minimum && magnitude <= maximum)
                );
            });
        });

        const isEnterpriseSelected = computedEager(() => {
            const data = formData.value;
            const name = data.planName;
            return name === 'Enterprise';
        });

        const isAnyPlanSelected = computedEager(() => {
            const data = formData.value;
            const id = data.planId;
            return typeof id === 'number' && Number.isSafeInteger(id);
        });

        const isNoPlanSelected = computedEager(() => {
            return isAnyPlanSelected.value !== true;
        });

        const isCityInputDisabled = computed(() => {
            const selectedValues = formData.value?.address;
            return (
                selectedValues?.country === '' ||
                selectedValues?.country === 'Select Country' ||
                selectedValues?.country === null
            );
        });

        const isStateInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.USA;
        });

        const isProvinceInputVisible = computed(() => {
            const selectedValues = formData.value?.address;
            return selectedValues?.country === CountryName.ByISO3.CAN;
        });

        const isRegionInputVisible = computed(() => {
            return isStateInputVisible.value || isProvinceInputVisible.value;
        });

        const isTermsAccepted = computed(() => {
            const selectedValue = formData.value?.terms;
            return selectedValue === true;
        });

        const isSubmissionAllowed = computedEager(() => {
            return (
                isEnterpriseSelected.value !== true &&
                isTermsAccepted.value === true
            );
        });

        const hasGeneralErrors = computedEager(() => {
            const error = errors.general.value ?? [];
            return error.length > 0;
        });

        const hasUsernameError = computedEager(() => {
            const error = errors.username.value ?? [];
            return error.length > 0;
        });

        const hasEmailError = computedEager(() => {
            const error = errors.email.value ?? [];
            return error.length > 0;
        });

        return {
            planOptions,
            durationOptions,
            enterpriseOption,
            isAnyPlanSelected,
            isNoPlanSelected,
            isCityInputDisabled,
            isProvinceInputVisible,
            isStateInputVisible,
            isRegionInputVisible,
            isEnterpriseSelected,
            isSubmissionAllowed,
            hasGeneralErrors,
            hasUsernameError,
            hasEmailError,
        };
    };

    /**
     * Define the component event hooks.
     */
    const defineEvents = () => {
        /** @type {EventHook<{ query: Router.LocationQuery }>} */
        const prefill = createEventHook();
        /** @type {EventHook<{ data?: Partial<globalThis.Organization.SignUpFormData> }>} */
        const submit = createEventHook();
        /** @type {EventHook<void>} */
        const reset = createEventHook();
        /** @type {EventHook<{ location?: string }>} */
        const redirect = createEventHook();
        return {
            prefill: prefill.trigger,
            submit: submit.trigger,
            reset: reset.trigger,
            redirect: redirect.trigger,
            onPrefill: prefill.on,
            onSubmit: submit.on,
            onReset: reset.on,
            onRedirect: redirect.on,
        };
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SignUpFields',
        components: {
            // FormSubmitCancel,
            VariantButton,
            FormSectionHeader,
        },
        props: {},
        setup(props, context) {
            // <!-- COMPOSABLES -->
            const router = useRouter();
            const { getMarketingLink } = useMarketingDomain();
            const regionSelectors = useRegionSelectors();
            const planDurations = useSubscriptionLength();
            const planSelector = usePlanSelector();
            const durationSelector = useDurationSelector();

            // <!-- STATE -->
            const {
                termsLink,
                prefillQuery,
                initialData,
                formData,
                planNode,
                errors,
                config,
            } = defineState();

            // <!-- COMPUTED PROPERTIES -->

            const {
                planOptions,
                durationOptions,
                enterpriseOption,
                isAnyPlanSelected,
                isNoPlanSelected,
                isCityInputDisabled,
                isProvinceInputVisible,
                isStateInputVisible,
                isRegionInputVisible,
                isEnterpriseSelected,
                isSubmissionAllowed,
                hasGeneralErrors,
                hasUsernameError,
                hasEmailError,
            } = defineComputed({
                termsLink,
                prefillQuery,
                initialData,
                formData,
                errors,
                config,
                planDurations,
                planSelector,
                durationSelector,
            });

            // <!-- EVENTS -->

            const {
                prefill,
                submit,
                reset,
                redirect,
                onPrefill,
                onSubmit,
                onReset,
                onRedirect,
            } = defineEvents();

            // <!-- ACTIONS -->

            /**
             * Hydrate the terms and conditions document link.
             */
            const bindMarketingLink = () => {
                termsLink.value = getMarketingLink('support/docs/terms/');
            };

            /**
             * Reset the form data using the defaults.
             */
            const resetFormData = () => {
                formData.value = clone({ ...initialData.value });
            };

            /**
             * Trigger event to prefill the form data using the passed location query.
             */
            const prefillFormDataWithQuery = () => {
                // Reset the form data to default state.
                // reset();
                // Get the latest query data.
                prefillQuery.value = router?.currentRoute.value?.query;
                // Select the prefill query code.
                prefill({ query: prefillQuery.value });
                // Clear the current route query.
                prefillQuery.value = null;
            };

            /**
             * Select the specific plan option.
             *
             * @param {string} value
             * @param {import('@formkit/core').FormKitNode} node
             */
            const selectPlanOption = (value, node) => {
                console.dir({ [`${node?.name ?? '?'}`]: value });
                formData.value.planId =
                    value === '' ? null : Number.parseInt(value);
            };

            /**
             * Convert the form data into a registration resource item.
             *
             * @param {typeof formData['value']} data
             * @returns {globalThis.Cart.Request.RegistrationResource}
             */
            const getSanitizedRequest = (data) => {
                /** @type {globalThis.Cart.Request.RegistrationResource['user']} */
                const user = {
                    username: data.username,
                    password: data.password,
                    password_confirmation: data.password_confirmation,
                    terms_accepted: data.terms === true,
                };

                /** @type {globalThis.Cart.Request.RegistrationResource['contact']} */
                const contact = {
                    first_name: data.firstname,
                    last_name: data.lastname,
                    primary_email: data.email?.contact,
                    billing_email: data.email?.billing,
                };

                // Convert the subscription details into proper forms.
                const duration = data.subscriptionLength;

                /** @type {globalThis.Cart.Request.RegistrationResource['organization']} */
                const organization = {
                    name: data.institution,
                    subscription_length: duration,
                    subscription_level_id: data.planId,
                    country: data.address?.country,
                    state: data.address?.state ?? '',
                    city: data.address?.city,
                };

                // Return the request object.
                return {
                    user,
                    contact,
                    organization,
                };
            };

            /**
             * Handle the output of the validation errors.
             * @param {string | { errors: { [error: string]: string[] }, message: string }} data
             */
            const handleValidationErrors = (data) => {
                if (typeof data === 'string') {
                    // Print the generic reason.
                    console.error(data);
                    return;
                }

                // Warn the user, using the passed message.
                console.warn(
                    `Failed to register new user and organization. ${data?.message}.`
                );

                // Handle errors individually.
                for (const error in data.errors) {
                    switch (error) {
                        case 'contact.primary_email':
                            console.warn(
                                'The submitted primary email address already belongs to an existing user.'
                            );
                            break;
                        case 'user.username':
                            console.warn(
                                'The submitted username already belongs to an existing user. Did you mean to log in?'
                            );
                            break;
                        default:
                            console.warn(data.errors[error].join(','));
                            break;
                    }
                }
            };

            /**
             * Redirect to the login page.
             */
            const redirectToLoginPage = async () => {
                await router.push({ name: 'Login' });
            };

            /**
             * Open the shopping cart in a new tab.
             *
             * @param {string} location
             */
            const redirectToShoppingCart = async (location) => {
                await redirectToLoginPage(); // Ensures we do not remain on the signup page.
                window.open(location, '_blank'); // Opens up a new tab with the pre-filled shopping cart.
            };

            /**
             * Handle the click submit button event.
             *
             * @param {MouseEvent} e
             * @param {import('@formkit/core').FormKitFrameworkContext} context
             */
            const clickSubmitButton = async (e, context) => {
                if (context.state.valid && !!isSubmissionAllowed.value) {
                    // Submit when the form is valid.
                    console.dir({ submit: e });
                    submit({ data: clone(formData.value) });
                } else {
                    // If the form is not valid, show + handle any errors.
                    console.error('Form is not valid.');
                    config.value = Object.assign(config.value, {
                        validationVisibility: 'live',
                    });
                }
            };

            /**
             * Clear out all existing errors.
             */
            const clearErrors = () => {
                errors.general.value = [];
                errors.email.value = [];
                errors.username.value = [];
                errors.plan.value = [];
            };

            /**
             * Set the plan node.
             *
             * @param {import('@formkit/core').FormKitNode} node
             */
            const setPlanNode = (node) => {
                planNode.value = node;
            };

            /**
             * Create an organization with the signup details.
             */
            const createOrganization = async () => {
                // Clear the errors.
                clearErrors();

                // Get the current form data.
                const selectedValues = clone({ ...formData.value });

                // Create the account name.
                const fullname = `${selectedValues.firstname} ${selectedValues.lastname}`;

                // Parse the subscription length.
                const subscriptionLength = DurationISO.parse(
                    selectedValues.subscriptionLength
                );

                // Get sanitized request.
                const request = {
                    account_level: selectedValues.planId, // Safe integer.
                    account_subscription_length:
                        selectedValues.planName === 'Free'
                            ? 1
                            : subscriptionLength?.years,
                    account_name: selectedValues.institution,
                    account_country: selectedValues.address?.country,
                    account_state:
                        selectedValues.address?.state?.slice(0, 2) ?? '',
                    account_city: selectedValues.address?.city,
                    first_name: selectedValues.firstname,
                    last_name: selectedValues.lastname,
                    user_name: selectedValues.username, // Must be unique.
                    email: selectedValues.email?.contact, // example@sharpnotions.com
                    billingEmail: selectedValues.email?.billing,
                    password: selectedValues.password,
                    password_confirmation: selectedValues.password_confirmation,
                };

                try {
                    // POST the register request.
                    const response = await axios().post(`/register`, request);

                    // Process based on the current form data values.
                    if (selectedValues.planName === 'Free') {
                        // Redirect to the Analysis page.
                        router.push('/analysis');
                    } else {
                        // window.location.replace(
                        //     `https://store.imagepermanenceinstitute.org/cart/add/e-${response.data.account.plan_code}_q${response.data.account.subscription_length}_z${response.data.organization?.id}?destination=cart`
                        // );
                    }
                } catch (error) {
                    errors.email.value.push(
                        error?.response?.data?.errors?.email
                    );
                    errors.username.value.push(
                        error?.response?.data?.errors?.user_name
                    );
                }
            };

            // <!-- VALIDATORS -->

            /**
             * Check if the provided email address already exists.
             * @param {import('@formkit/core').FormKitNode} input
             */
            const isPrimaryEmailValid = async (input) => {
                // Check if user already exists
                const target = String(input.value);

                // Send the request for a redirect.
                const response = await users.availableUserEmail({
                    email: target,
                });

                return response.isOk && response.value === true;
            };

            /**
             * Check if the provided username already exists.
             * @param {import('@formkit/core').FormKitNode} input
             */
            const isUsernameValid = async (input) => {
                // Check if user already exists
                const target = String(input.value);

                // Send the request for a redirect.
                const response = await users.availableUserName({
                    username: target,
                });

                return response.isOk && response.value === true;
            };

            // <!-- WATCHERS -->

            // When the subscription plan changes, select the new plan information.
            const { trigger: notifyPlanSelected } = watchTriggerable(
                () => formData.value.planId,
                (current, previous) => {
                    // Check if duration is different.
                    if (previous === undefined || current !== previous) {
                        // Get the plan summary data.
                        const info = !!current
                            ? planSelector.getPlanInformation(current)
                            : {};
                        formData.value.planName = info.name ?? 'Free';

                        // Forcibly refresh the plan node input to select the right one.
                        planNode.value?.input(`${formData.value.planId}`);
                    }
                }
            );

            // <!-- LIFECYCLE -->

            // BEFORE MOUNTED
            onBeforeMount(async () => {
                await planSelector.fetchPlans();
                bindMarketingLink();
                prefillFormDataWithQuery();
            });

            // ON FORM RESET
            onReset(() => {
                resetFormData();
            });

            // ON PREFILL
            onPrefill(({ query }) => {
                // Get snapshot of the current form data.
                const data = {
                    planId: 5,
                    planName: 'Free',
                    subscriptionLength: DurationISO.format({ months: 3 }),
                };

                // Designate the selector.
                const selector = String(query?.account_level ?? '');

                // Update the form data based on the given selector.
                switch (selector) {
                    case 'free':
                        data.planId = 5;
                        data.planName = 'Free';
                        data.subscriptionLength = DurationISO.format({
                            months: 3,
                        });
                        break;
                    case 'level1':
                    case 'level2':
                    case 'level3':
                    case 'level4':
                        // Get the available options.
                        const options = planOptions.value ?? [];

                        // Find the selected option.
                        const selected = options?.find((option) => {
                            if (option.label.includes(' ')) {
                                const [level, number] = option.label.split(' ');
                                const key = `${level.toLowerCase()}${number}`;
                                return key === selector;
                            }
                            return false;
                        });

                        // Using the selected option, get the plan information.
                        if (!!selected) {
                            const id = Number(selected.value);
                            const info = planSelector.getPlanInformation(id);
                            data.planId = id;
                            data.planName = info.name;
                            data.subscriptionLength = DurationISO.format({
                                years: 1,
                            });
                        }
                        break;
                    case 'enterprise':
                        data.planId = 14;
                        data.planName = 'Enterprise';
                        data.subscriptionLength = DurationISO.format({
                            years: 1,
                        });
                        break;
                }

                // When not the free trial, update the length.
                if (selector !== 'free') {
                    // Get the supplied length.
                    const length = parseInt(String(query?.subscription_length));
                    // Update the length.
                    data.subscriptionLength =
                        Number.isNaN(length) || !Number.isSafeInteger(length)
                            ? data.subscriptionLength // re-use existing
                            : DurationISO.format({ years: length });
                }

                // Update the form data with hydrated prefill details.
                formData.value.subscriptionLength = data.subscriptionLength;
                formData.value.planId = data.planId;
                notifyPlanSelected();
            });

            // ON FORM SUBMISSION
            onSubmit(async () => {
                // Get the sanitized request.
                const request = getSanitizedRequest(formData.value);

                // Submit the registration command.
                const result = await submitRegistration(request);

                // Get the good data out, if possible.
                const data = result.isOk ? result.value : null;

                // Handle bad result.
                if (result.isErr) {
                    // Failed to register the organization.
                    console.error(
                        'Registration failed. See response for more details.'
                    );

                    // Determine if validation exception was raised.
                    const exception =
                        result.isErr && result.error?.isAxiosError
                            ? result.error
                            : null;

                    // View the validation errors.
                    handleValidationErrors(exception.response.data);

                    // Exit early.
                    return;
                }

                // Assume good result.
                if (data) {
                    // Show successful notification.
                    // TODO: Use alerts?
                    console.log(
                        `Registration was successful. Created new user ${data.user?.username}.`
                    );

                    // Delay so user can read the notifications, if necessary.
                    await promiseTimeout(
                        200,
                        false,
                        'Delay before redirecting.'
                    );

                    // Execute the redirect based on the requested subscription type.
                    redirect({ location: data.redirect });
                }
            });

            // ON FORM REDIRECT
            onRedirect(async ({ location }) => {
                if (location === 'login') {
                    await redirectToLoginPage();
                } else {
                    await redirectToShoppingCart(location);
                }
            });

            // EXPOSE
            return {
                // UTILITIES
                DurationISO,
                // STATE
                debug: process.env.NODE_ENV !== 'production',
                termsLink,
                initialData,
                formData,
                errors,
                config,
                // OPTIONS
                planOptions,
                durationOptions,
                enterpriseOption,
                countryOptions: regionSelectors.country.options,
                stateOptions: regionSelectors.state.options,
                provinceOptions: regionSelectors.province.options,
                // CONDITIONALS
                isAnyPlanSelected,
                isNoPlanSelected,
                isCityInputDisabled,
                isProvinceInputVisible,
                isStateInputVisible,
                isRegionInputVisible,
                isEnterpriseSelected,
                isSubmissionAllowed,
                hasGeneralErrors,
                hasUsernameError,
                hasEmailError,
                // ACTIONS
                notifyPlanSelected,
                selectPlanOption,
                setPlanNode,
                clickSubmitButton,
                createOrganization,
                isPrimaryEmailValid,
                isUsernameValid,
            };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
