<template>
    <div class="pb-32">
        <div
            class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2
                    class="mt-6 text-center text-3xl font-extrabold text-gray-900"
                >
                    Forgot Your Password?
                </h2>
                <div class="mt-6 text-center text-gray-500">
                    <p>{{ emailPrompt }}</p>
                </div>
            </div>
        </div>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoadingWrapper :isLoading="isLoading">
                    <div
                        v-if="emailSent"
                        class="rounded-md bg-green-50 p-4 mb-4"
                    >
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <BadgeCheckIcon
                                    class="h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3">
                                <div class="text-sm text-green-700">
                                    <p>
                                        We have emailed your password reset link
                                        if that username exists.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormKit
                        type="form"
                        id="forgot-password-form"
                        :actions="false"
                        v-model="formData"
                        #default="context"
                        :config="config"
                        :errors="formErrors"
                        aria-autocomplete="on"
                        autocomplete="on"
                    >
                        <!-- Username -->
                        <FormKit
                            type="email"
                            id="email"
                            name="email"
                            label="Email"
                            aria-autocomplete="email"
                            autocomplete="email"
                            outer-class="outer mt-1"
                            label-class="$reset label block text-sm font-bold text-gray-700"
                            input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                            validation="required|email"
                            validation-label="Your email"
                            :placeholder="'Enter your email...'"
                            @keydown.enter.prevent.stop="clickResetButton"
                        />

                        <div class="flex justify-center">
                            <div class="min-w-full mx-auto mb-2">
                                <VariantButton
                                    name="reset-password"
                                    variant="login"
                                    label="Reset Your Password"
                                    @click.stop="onResetSubmit"
                                    :disabled="
                                        !context.state.valid && !isLoading
                                    "
                                />
                            </div>
                        </div>
                    </FormKit>
                    <div class="flex flex-col justify-center mt-4">
                        <router-link
                            to="/login"
                            class="w-full text-center text-primary-600 text-sm hover:text-primary-400"
                        >
                            Return to Login
                        </router-link>
                    </div>
                </LoadingWrapper>
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import pick from 'just-pick';
    import profile from '@/api/v2/profile';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import { BadgeCheckIcon } from '@heroicons/vue/outline';
    import { clickSiblingFormElement, withEventTarget } from '@/utils/html';

    // <!-- TYPES -->

    /** @typedef {Router.Router} Router */

    /** @typedef {import('@formkit/core').FormKitProps} FormKitProps */
    /** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ForgotPassword',
        components: {
            VariantButton,
            LoadingWrapper,
            BadgeCheckIcon,
        },
        props: {
            /** FormKit configuration */
            config: {
                /** @type {Vue.PropType<Partial<FormKitConfig & FormKitProps>>} */
                type: Object,
                /** @type {() => Partial<FormKitConfig & FormKitProps>} */
                default: () => ({
                    delay: 200,
                    validationVisibility: 'blur',
                }),
            },
        },
        setup(props, context) {
            const router = useRouter();
            const isLoading = ref(false);
            const emailSent = ref(false);

            /** @type {String} */
            const emailPrompt = `Enter your email and we'll send you a link to reset your password.`;

            /** @type {Vue.Ref<{ email: String, errors: Set<String> }>} */
            const formData = ref({ email: '', errors: new Set() });

            /** @type {Vue.ComputedRef<Boolean>} Does the form have an input email? */
            const hasInput = computed(() => formData.value.email.length > 0);

            /** @type {Vue.ComputedRef<Boolean>} Does the form have errors? */
            const hasErrors = computed(() => formData.value.errors.size > 0);

            /** @type {Vue.ComputedRef<String[]>} Form errors. */
            const formErrors = computed(() => [...formData.value.errors]);

            const onClearErrors = () => {
                formData.value.errors.clear();
            };

            /**
             * Click the reset button, using another element's event.
             * @param {{ target: HTMLInputElement }} e
             */
            const clickResetButton = (e) => {
                withEventTarget(e).then((target) => {
                    clickSiblingFormElement(target, 'reset-password');
                });
            };

            /** @param {Event} event Click event. */
            async function onResetSubmit(event) {
                /** @type {Pick<formData['value'], 'email'>} */
                const request = pick(formData.value, 'email');

                try {
                    onClearErrors();
                    console.groupCollapsed(`[forgot::password]`);
                    console.dir({ data: formData.value, submit: event });
                    const response = await profile.forgetPassword(request);
                    console.dir(response);
                    // If successful, set to true.
                    emailSent.value = true;
                } catch (error) {
                    const { message } = error;
                    console.error(message);
                    formData.value.errors.add(message);
                    // If failure, set to false.
                    emailSent.value = false;
                } finally {
                    console.groupEnd();
                }
            }

            return {
                isLoading,
                clickResetButton,
                onResetSubmit,
                formData,
                formErrors,
                hasInput,
                hasErrors,
                emailSent,
                emailPrompt,
            };
        },
    });
</script>
