<template>
    <div class="pb-32">
        <div
            class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2
                    class="mt-6 text-center text-3xl font-extrabold text-gray-900"
                >
                    Reset Your Password
                </h2>
                <div class="mt-6 text-center text-gray-500">
                    <p>{{ ResetPasswordPrompt }}</p>
                </div>
            </div>
        </div>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <LoadingWrapper :isLoading="isLoading">
                    <div
                        v-if="isPasswordReset && hasLabel && !isLoading"
                        class="rounded-md bg-green-50 p-4 mb-4"
                    >
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <BadgeCheckIcon
                                    class="h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3">
                                <div class="text-sm text-green-700">
                                    <p>{{ formLabel }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!isPasswordReset && hasLabel && !isLoading"
                        class="rounded-md bg-red-50 p-4 mb-4"
                    >
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <ExclamationCircleIcon
                                    class="h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3">
                                <div class="text-sm text-red-700">
                                    <p>{{ formLabel }}</p>
                                    <ul
                                        v-if="hasErrors"
                                        class="list-disc list-inside"
                                    >
                                        <li
                                            v-for="error of formErrors"
                                            :key="error"
                                        >
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="isRedirecting"
                        class="rounded-md bg-primary-50 p-4 mb-4"
                    >
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <InformationCircleIcon
                                    class="h-5 w-5 text-primary-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="ml-3">
                                <div class="text-sm text-primary-700">
                                    <p>
                                        You are being redirected to another
                                        page.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoadingWrapper :isLoading="isRedirecting">
                        <FormKit
                            type="form"
                            id="reset-password-form"
                            :actions="false"
                            v-model="formData"
                            :config="config"
                            :errors="formErrors"
                            aria-autocomplete="on"
                            autocomplete="on"
                            #default="context"
                        >
                            <!-- Email -->
                            <FormKit
                                type="text"
                                id="email"
                                name="email"
                                label="Email"
                                aria-autocomplete="email"
                                autocomplete="email"
                                outer-class="outer mt-1"
                                label-class="$reset label block text-sm font-bold text-gray-700"
                                input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                validation="required:trim|length:1,255|*email"
                                validation-label="Your email"
                                validation-visibility="live"
                                :placeholder="'Enter your email...'"
                                @keydown.enter.prevent.stop="
                                    focusFormInput($event, 'password')
                                "
                            />
                            <!-- New Password -->
                            <FormKit
                                type="password"
                                id="password"
                                name="password"
                                label="New Password"
                                aria-autocomplete="new-password"
                                autocomplete="new-password"
                                outer-class="outer mt-1"
                                label-class="$reset label block text-sm font-bold text-gray-700"
                                input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                validation="required:trim|length:8,255|*letters|*mixedCase|*digits|*symbols"
                                validation-label="Your new password"
                                validation-visibility="live"
                                :validation-rules="{ ...rules }"
                                :validation-messages="ValidationMessages"
                                :placeholder="'Enter your new password...'"
                                @keydown.enter.prevent.stop="
                                    focusFormInput($event, 'password_confirm')
                                "
                            />
                            <!-- New Password (Confirmation) -->
                            <FormKit
                                type="password"
                                id="password_confirm"
                                name="password_confirm"
                                label="Confirm Password"
                                aria-autocomplete="new-password"
                                autocomplete="new-password"
                                outer-class="outer mt-1"
                                label-class="$reset label block text-sm font-bold text-gray-700"
                                input-class="$reset text-input appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                validation="*required:trim|*+confirm"
                                validation-label="Your new password"
                                validation-visibility="live"
                                :validation-rules="{ ...rules }"
                                :validation-messages="ValidationMessages"
                                :placeholder="'Enter your new password again...'"
                                @keydown.enter.prevent.stop="clickResetButton"
                            />
                            <div class="flex justify-center">
                                <div class="min-w-full mx-auto mb-2">
                                    <VariantButton
                                        name="reset-password"
                                        variant="login"
                                        :label="
                                            isRedirecting
                                                ? `Redirecting...`
                                                : `Reset Your Password`
                                        "
                                        @click="events.clickSubmit"
                                        :disabled="
                                            !context.state.valid ||
                                            isLoading ||
                                            isRedirecting
                                        "
                                    />
                                </div>
                            </div>
                        </FormKit>
                        <div class="flex flex-col justify-center mt-4">
                            <router-link
                                to="/password/forgotten"
                                class="w-full text-center text-primary-600 text-sm hover:text-primary-400"
                            >
                                Return to Forgot Password
                            </router-link>
                        </div>
                    </LoadingWrapper>
                </LoadingWrapper>
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import profile from '@/api/v2/profile';
    import { defineComponent, ref, computed, onBeforeMount } from 'vue';

    // <!-- COMPOSABLES -->
    import { useRouter } from 'vue-router';

    // <!-- UTILITIES -->
    import omit from 'just-omit';
    import clone from 'just-clone';
    import merge from 'lodash-es/merge';
    import compare from 'just-compare';
    import { diff, jsonPatchPathConverter } from 'just-diff';
    import {
        focusSiblingFormElement,
        clickSiblingFormElement,
        withEventTarget,
    } from '@/utils/html';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import {
        BadgeCheckIcon,
        ExclamationCircleIcon,
        InformationCircleIcon,
    } from '@heroicons/vue/outline';

    /** @typedef {import('@formkit/core').FormKitProps} FormKitProps */
    /** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */

    /**
     * @typedef {Object} IResetPasswordFormData
     * @property {String} email
     * @property {String} password
     * @property {String} password_confirm
     * @property {String} label
     * @property {String[]} errors
     */

    /** @typedef {import('@/api/v2/profile').IResetPasswordRequest} IResetPasswordRequest */
    /** @typedef {import('@/api/v2/profile').IResetPasswordResult} IResetPasswordResult */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ForgotPassword',
        components: {
            VariantButton,
            LoadingWrapper,
            BadgeCheckIcon,
            ExclamationCircleIcon,
            InformationCircleIcon,
        },
        props: {
            /** FormKit configuration */
            config: {
                /** @type {V.PropType<Partial<FormKitConfig & FormKitProps>>} */
                type: Object,
                /** @type {() => Partial<FormKitConfig & FormKitProps>} */
                default: () => ({
                    delay: 200,
                    validationVisibility: 'live',
                }),
            },
        },
        setup(props, context) {
            // <!-- COMPOSABLES -->

            /**
             * Invoke the focus event on the password input element.
             * @param {{ target: HTMLInputElement }} e
             * @param {String} name
             */
            const focusFormInput = (e, name) => {
                withEventTarget(e).then((target) =>
                    focusSiblingFormElement(target, name)
                );
            };

            /**
             * Invoke the click event on the login button element.
             * @param {{ target: HTMLInputElement }} e
             */
            const clickResetButton = (e) => {
                withEventTarget(e).then((target) =>
                    clickSiblingFormElement(target, 'reset-password')
                );
            };

            /**
             * Define the password reset context.
             * @param {props} props
             * @param {context} context
             * @returns {Readonly<IPasswordResetContext>}
             */
            const usePasswordReset = (props, context) => {
                /**
                 * @typedef {Object} IPasswordResetContext
                 * @property {ReturnType<useState>} state
                 * @property {ReturnType<useProperties>} properties
                 * @property {ReturnType<useMethods>} methods
                 */

                /** Define the local state. */
                const useState = () => {
                    /** Define the constants. */
                    const useConstants = () => {
                        // <!-- DEFINE -->
                        /** Reset password prompt. */
                        const ResetPasswordPrompt = /** @type {const} */ (
                            `Reset your password using the form below.`
                        );
                        /** @type {Readonly<IResetPasswordFormData>} */
                        const DefaultFormData = {
                            /** @type {String} */
                            email: '',
                            /** @type {String} */
                            password: '',
                            /** @type {String} */
                            password_confirm: '',
                            /** @type {String} */
                            label: '',
                            /** @type {String[]} */
                            errors: [],
                        };
                        /** @type {Record<String, (params: { args: any, name: String, node: import('@formkit/core').FormKitNode }) => String>} */
                        const ValidationMessages = {
                            letters: ({ name }) =>
                                `${name} must have at least one letter.`,
                            mixedCase: ({ name }) =>
                                `${name} must have at least one uppercase letter and one lowercase letter.`,
                            digits: ({ name }) =>
                                `${name} must have at least one digit.`,
                            symbols: ({ name }) =>
                                `${name} must have at least one of the following symbols: "${[
                                    ...'@$!%*#?&',
                                ]}".`,
                        };
                        // <!-- EXPOSE -->
                        return {
                            ResetPasswordPrompt,
                            DefaultFormData,
                            ValidationMessages,
                        };
                    };
                    /** Define the reactive state. */
                    const useReactive = () => {
                        // <!-- DEFINE -->
                        /** Vue Router instance. */
                        const router = useRouter();
                        /** @type {V.Ref<Boolean>} Is there a request loading? */
                        const loading = ref(false);
                        /** @type {V.Ref<Boolean>} Is there a redirect in progress? */
                        const redirecting = ref(false);
                        /** @type {V.Ref<Boolean>} Has the password been reset? */
                        const passwordReset = ref(false);
                        /** @type {V.Ref<IResetPasswordFormData>} */
                        const formData = ref({
                            /** @type {String} */
                            email: '',
                            /** @type {String} */
                            password: '',
                            /** @type {String} */
                            password_confirm: '',
                            /** @type {String} */
                            label: '',
                            /** @type {String[]} */
                            errors: [],
                        });
                        // <!-- EXPOSE -->
                        return {
                            router,
                            loading,
                            redirecting,
                            passwordReset,
                            formData,
                        };
                    };
                    // <!-- EXPOSE -->
                    return {
                        ...useConstants(),
                        ...useReactive(),
                    };
                };

                /**
                 * Define the computed properties.
                 * @param {Pick<IPasswordResetContext, 'state'>} props
                 */
                const useProperties = ({ state }) => {
                    /**
                     * Define computed properties based on the router.
                     * @param {Router.Router} router
                     */
                    const useRouterData = (router) => {
                        // <!-- DEFINE -->
                        const currentRoute = computed(
                            () => router.currentRoute.value
                        );
                        const currentQuery = computed(
                            () => currentRoute.value.query
                        );
                        const currentToken = computed(() => {
                            if (
                                'token' in currentQuery.value &&
                                typeof currentQuery.value.token === 'string'
                            ) {
                                return currentQuery.value.token;
                            }
                            return '';
                        });
                        /** Does the page have a reset password token? */
                        const hasToken = computed(
                            () => currentToken.value.trim().length > 0
                        );
                        // <!-- EXPOSE -->
                        return {
                            currentRoute,
                            currentQuery,
                            currentToken,
                            hasToken,
                        };
                    };
                    /**
                     * Define computed properties based on a reactive formData reference.
                     * @param {V.Ref<IResetPasswordFormData>} formData
                     */
                    const useFormData = (formData) => {
                        // <!-- DEFINE -->
                        /** @type {V.ComputedRef<String[]>} Distinct form errors. */
                        const formErrors = computed(() => {
                            const errors = [...new Set(formData.value.errors)];
                            return errors;
                        });
                        /** @type {V.ComputedRef<String>} Distinct form messages. */
                        const formLabel = computed(() => {
                            const label = formData.value.label ?? '';
                            return label;
                        });
                        /** @type {V.ComputedRef<Boolean>} Does the form have an input email? */
                        const hasEmail = computed(
                            () => formData.value.email.length > 0
                        );
                        /** @type {V.ComputedRef<Boolean>} Does the form have an input new password? */
                        const hasPassword = computed(
                            () => formData.value.password.length > 0
                        );
                        /** @type {V.ComputedRef<Boolean>} Does the form have an input confirmed password? */
                        const hasPasswordConfirmation = computed(
                            () =>
                                formData.value.password_confirm.length > 0 &&
                                formData.value.password_confirm ===
                                    formData.value.password
                        );
                        /** @type {V.ComputedRef<Boolean>} Does the form have errors? */
                        const hasErrors = computed(
                            () => formErrors.value.length > 0
                        );
                        /** @type {V.ComputedRef<Boolean>} Does the form have errors? */
                        const hasLabel = computed(
                            () => formLabel.value.length > 0
                        );
                        // <!-- EXPOSE -->
                        return {
                            formErrors,
                            formLabel,
                            hasEmail,
                            hasPassword,
                            hasPasswordConfirmation,
                            hasErrors,
                            hasLabel,
                        };
                    };
                    /**
                     * Define conditionals.
                     * @param {IPasswordResetContext['state']} state
                     */
                    const useConditionals = (state) => {
                        // <!-- DEFINE -->
                        /** @type {V.ComputedRef<Boolean>} Is there a request loading? */
                        const isLoading = computed(() => {
                            return state.loading.value === true;
                        });
                        /** @type {V.ComputedRef<Boolean>} Is there a redirect in progress? */
                        const isRedirecting = computed(() => {
                            return state.redirecting.value === true;
                        });
                        /** @type {V.ComputedRef<Boolean>} Has the password been reset? */
                        const isPasswordReset = computed(() => {
                            return state.passwordReset.value === true;
                        });
                        // <!-- EXPOSE -->
                        return {
                            isLoading,
                            isRedirecting,
                            isPasswordReset,
                        };
                    };
                    // <!-- EXPOSE -->
                    return {
                        ...useRouterData(state.router),
                        ...useFormData(state.formData),
                        ...useConditionals(state),
                    };
                };

                /**
                 * Define the interface.
                 * @param {Pick<IPasswordResetContext, 'state' | 'properties'>} props
                 */
                const useMethods = ({ state, properties }) => {
                    // <!-- UTILITIES -->
                    /**
                     * @template [T=any]
                     * Compare two object instances to determine if they are dirty.
                     * @param {Readonly<T>} clean
                     * @param {Readonly<T>} dirty
                     */
                    const isDirty = (clean, dirty) => {
                        const isEqual = compare(clean, dirty);
                        const differences = isEqual
                            ? []
                            : diff(clean, dirty, jsonPatchPathConverter);
                        return differences.length > 0;
                    };
                    /**
                     * Create form request from the input form data.
                     * @param {Readonly<IResetPasswordFormData>} source
                     * @param {String} token
                     * @returns {IResetPasswordRequest}
                     */
                    const createFormRequest = (source, token) => {
                        /** @type {Omit<IResetPasswordFormData, 'errors'>} */
                        const data = omit(clone(source), 'errors');
                        /** @type {Readonly<IResetPasswordRequest>} */
                        const request = {
                            token,
                            email: data.email,
                            password: data.password,
                            password_confirmation: data.password_confirm,
                        };
                        return request;
                    };
                    // <!-- METHODS -->
                    /** Define field validators. */
                    const useFieldRules = () => {
                        // <!-- DEFINE -->
                        /**
                         * Value must have at least one letter.
                         * @param {import('@formkit/core').FormKitNode} node
                         */
                        const letters = (node) => {
                            if (!!node && typeof node?.value === 'string') {
                                const value = node.value;
                                const allowed = /[a-zA-Z]/; // Contains at least one letter.
                                const isPresent = value.length > 0;
                                const hasLetter = allowed.test(value);
                                return isPresent && hasLetter;
                            }
                            // Value is required.
                            return false;
                        };
                        /**
                         * Value must have at least one uppercase letter and one lowercase letter.
                         * @param {import('@formkit/core').FormKitNode} node
                         */
                        const mixedCase = (node) => {
                            if (!!node && typeof node?.value === 'string') {
                                const value = node.value;
                                const uppercase = /[A-Z]/; // Contains at least one uppercase letter.
                                const lowercase = /[a-z]/; // Contains at least one lowercase letter.
                                const isPresent = value.length > 0;
                                const hasMixedCase =
                                    uppercase.test(value) &&
                                    lowercase.test(value);
                                return isPresent && hasMixedCase;
                            }
                            // Value is required.
                            return false;
                        };
                        /**
                         * Value must have at least one number.
                         * @param {import('@formkit/core').FormKitNode} node
                         */
                        const digits = (node) => {
                            if (!!node && typeof node?.value === 'string') {
                                const value = node.value;
                                const allowed = /\d/; // Contains at least one digit.
                                const isPresent = value.length > 0;
                                const hasDigit = allowed.test(value);
                                return isPresent && hasDigit;
                            }
                            // Value is required.
                            return false;
                        };
                        /**
                         * Value must have at least one symbol.
                         * @param {import('@formkit/core').FormKitNode} node
                         */
                        const symbols = (node) => {
                            if (!!node && typeof node?.value === 'string') {
                                const value = node.value;
                                const allowed = new RegExp(`[@$!%*#?&]`); // Contains at least one of the symbols in the character array.
                                const isPresent = value.length > 0;
                                const hasSymbol = allowed.test(value);
                                return isPresent && hasSymbol;
                            }
                            // Value is required.
                            return false;
                        };
                        // <!-- EXPOSE -->
                        return {
                            letters,
                            mixedCase,
                            digits,
                            symbols,
                        };
                    };
                    /**
                     * Define form methods.
                     * @param {state} state Reactive formData reference.
                     */
                    const useFormMethods = ({ formData }) => {
                        // <!-- DEFINE -->
                        /**
                         * @template {keyof IResetPasswordFormData} T
                         * Get value from the current formData.
                         * @param {T} key
                         * @returns {IResetPasswordFormData[T]}
                         */
                        const select = (key) => {
                            return formData.value[key];
                        };
                        /**
                         * Set reactive formData using copy of input data.
                         * @param {Readonly<IResetPasswordFormData>} value
                         */
                        const set = (value) => {
                            /** @type {IResetPasswordFormData} */
                            const next = clone(value);
                            return (formData.value = next);
                        };
                        /** Reset the formData using the default form data. */
                        const reset = () => {
                            return set(state.DefaultFormData);
                        };
                        /**
                         * Merge reactive formData using copy of input data, but only if resulting update is dirty.
                         * @param {Partial<Readonly<IResetPasswordFormData>>} dirty
                         */
                        const update = (dirty) => {
                            /** @type {IResetPasswordFormData} */
                            const previous = clone(formData.value);
                            /** @type {IResetPasswordFormData} */
                            const next = merge({}, previous, clone(dirty));
                            // Test if changes have been made.
                            const isNextDirty = isDirty(previous, next);
                            console.log(`[update::form] =>`, {
                                isNextDirty,
                                previous,
                                next,
                            });
                            if (isNextDirty) {
                                // Assign merged data, if dirty.
                                return (formData.value = next);
                            }
                            // If no change, return existing data.
                            return formData.value;
                        };
                        /** Flush errors and label from the reactive formData, but preserve any other data. */
                        const flush = () => {
                            /** @type {IResetPasswordFormData} */
                            const next = clone(formData.value);
                            next.label = '';
                            next.errors = [];
                            return set(next);
                        };
                        /** Create form request from the current reactive formData. */
                        const toRequest = () => {
                            return createFormRequest(
                                formData.value,
                                properties.currentToken.value
                            );
                        };
                        // <!-- EXPOSE -->
                        return {
                            select,
                            set,
                            reset,
                            update,
                            flush,
                            toRequest,
                        };
                    };
                    // <!-- EVENTS -->
                    /**
                     * Define component lifecycle events.
                     * @param {state} state
                     * @param {properties} properties
                     */
                    const useLifecycleEvents = (state, properties) => {
                        // <!-- DEFINE -->
                        /** Find the current token or redirect to the forgotten password page. */
                        const findTokenOrRedirect = async () => {
                            const token = properties.currentToken.value;
                            const isTokenPresent = properties.hasToken.value;
                            if (isTokenPresent) {
                                // If token is present, simply return the token.
                                console.log(`[token::found]`, {
                                    token,
                                });
                                return token;
                            } else {
                                // If token is missing, redirect so user can request a valid password reset link.
                                console.log(
                                    `[token::missing] => Redirecting to '/password/forgotten'`
                                );
                                await state.router.push({
                                    path: '/password/forgotten',
                                });
                                return '';
                            }
                        };
                        /** Initialize the form data. */
                        const initialize = async () => {
                            // Reset the form data.
                            form.reset();
                            // Reset password reset flag.
                            state.passwordReset.value = false;
                            // Reset the loading flag.
                            state.loading.value = false;
                            // Reset the redirecting flag.
                            state.redirecting.value = false;
                        };
                        /**
                         * Event invoked when submit is clicked.
                         * @param {Event} event
                         */
                        const clickSubmit = async (event) => {
                            // Log event.
                            console.log(`[click::submit] =>`, { event });
                            // Create the form request.
                            const request = form.toRequest();
                            // Send API callback.
                            const result =
                                await events.onSendPasswordResetRequest(
                                    request
                                );
                            // Special handlers for specific statuses.
                            switch (result.status) {
                                case 403:
                                    // If missing token (403), redirect to the forgotten password page.
                                    await events.onInvalidTokenError(5000);
                                    break;
                                case 401:
                                    // If invalid email, clear the email field.
                                    await events.onInvalidUserError();
                                    break;
                                case 200:
                                    // If reset successfully (200), redirect to the login page.
                                    await events.onResetPasswordSuccess(5000);
                                    break;
                                case 500:
                                default:
                                    break;
                            }
                        };
                        // <!-- EXPOSE -->
                        return {
                            initialize,
                            findTokenOrRedirect,
                            clickSubmit,
                        };
                    };
                    /**
                     * Define form event handlers.
                     * @param {state} state
                     * @param {properties} properties
                     */
                    const useFormEvents = (state, properties) => {
                        // <!-- DEFINE -->

                        /**
                         * Define submit event handler.
                         * @param {IResetPasswordRequest} request
                         * @returns {Promise<IResetPasswordResult>}
                         */
                        const onSendPasswordResetRequest = async (request) => {
                            /** @type {IResetPasswordResult} */
                            let result = {
                                status: 500,
                                label: '',
                                errors: new Set(),
                            };
                            try {
                                // Start loading.
                                state.loading.value = true;
                                if (!!request && properties.hasToken.value) {
                                    // Log event.
                                    console.log(`[reset::password] =>`, {
                                        request,
                                    });
                                    // Flush errors once request has been created.
                                    form.flush();
                                    // If request is present, attempt to POST body to /reset-password endpoint.
                                    const response =
                                        await profile.resetPassword(request);
                                    // Return the result.
                                    if (response.isOk) {
                                        result.status = 200;
                                        result.label = 'Success';
                                        return result;
                                    }
                                }
                            } catch (e) {
                                // Add unknown error message.
                                result.errors.add(
                                    e?.message ?? 'An unknown error occurred.'
                                );
                            } finally {
                                // Stop loading.
                                state.loading.value = false;
                                // Update flag.
                                state.passwordReset.value =
                                    result.status === 200;
                                // Update form data with response errors and messages.
                                form.update({
                                    email: request.email,
                                    password: request.password,
                                    password_confirm:
                                        request.password_confirmation,
                                    label: result.label,
                                    errors: [...result.errors],
                                });
                            }
                        };

                        /** Redirect to the forgotten password page after a set delay. */
                        const onInvalidTokenError = async (delay = 1000) => {
                            state.redirecting.value = true;
                            await new Promise((resolve) => {
                                // Wait before redirecting to the forgotten password page.
                                setTimeout(resolve, delay);
                            });
                            state.redirecting.value = false;
                            await state.router.push(`/password/forgotten`);
                        };

                        /** Clear the email field when the user email is invalid. */
                        const onInvalidUserError = async () => {
                            const updated = form.update({ email: '' });
                        };

                        /** Redirect to the login page after a set delay. */
                        const onResetPasswordSuccess = async (delay = 1000) => {
                            state.redirecting.value = true;
                            await new Promise((resolve) => {
                                // Wait before redirecting to the login page.
                                setTimeout(resolve, delay);
                            });
                            state.redirecting.value = false;
                            await state.router.push(`/login`);
                        };

                        // <!-- EXPOSE -->
                        return {
                            onSendPasswordResetRequest,
                            onInvalidTokenError,
                            onInvalidUserError,
                            onResetPasswordSuccess,
                        };
                    };
                    // <!-- DEFINE -->
                    const rules = useFieldRules();
                    const form = useFormMethods(state);
                    const events = {
                        ...useLifecycleEvents(state, properties),
                        ...useFormEvents(state, properties),
                    };
                    // <!-- EXPOSE -->
                    return {
                        isDirty,
                        createFormRequest,
                        form,
                        rules,
                        events,
                    };
                };

                // <!-- DEFINE -->
                const state = useState();
                const properties = useProperties({ state });
                const methods = useMethods({ state, properties });

                // <!-- EXPOSE -->
                return {
                    state,
                    properties,
                    methods,
                };
            };

            // <!-- DEFINE -->
            const { state, properties, methods } = usePasswordReset(
                props,
                context
            );

            // <!-- DESTRUCTURE -->
            const { ResetPasswordPrompt, ValidationMessages, formData } = state;
            const {
                currentRoute,
                currentQuery,
                currentToken,
                formErrors,
                formLabel,

                isLoading,
                isRedirecting,
                isPasswordReset,

                hasToken,
                hasEmail,
                hasPassword,
                hasPasswordConfirmation,
                hasErrors,
                hasLabel,
            } = properties;
            const { form, rules, events } = methods;

            // <!-- LIFECYCLE -->
            // Before mounting...
            onBeforeMount(async () => {
                // ...initialize the form.
                await events.initialize();
                // ...get the current token from the URL query string.
                // ...if no token is found, redirect to forgotten password page.
                await events.findTokenOrRedirect();
            });

            // <!-- EXPOSE -->
            return {
                ResetPasswordPrompt,
                ValidationMessages,
                formData,

                currentRoute,
                currentQuery,
                currentToken,
                formErrors,
                formLabel,

                isLoading,
                isRedirecting,
                isPasswordReset,

                hasToken,
                hasEmail,
                hasPassword,
                hasPasswordConfirmation,
                hasErrors,
                hasLabel,

                form,
                rules,
                events,
                focusFormInput,
                clickResetButton,
            };
        },
    });
</script>
